import './style.scss';
import { Box, Button, Grid, Typography } from "@mui/material";
import { useLanguageContext } from '../../../../contexts/LanguageContext';
import DefaultConstants from '../../../../data/Constants';
import { history } from '../../../../router/BrowserHistory';

const Services = (props) => {
    const { translate } = useLanguageContext();

    return <>
        <Grid container id='services-section'>
            <Grid item xs={12} className='grid-content-section padding-none'>
                <Box className='wallpaper small' sx={{ backgroundImage: `url(${DefaultConstants.services.wallpaper})` }}>
                    <Typography className='wallpaper-title'>
                        {translate(DefaultConstants.services.title)}
                    </Typography>

                    <Typography className='wallpaper-subtitle'>
                        {translate(DefaultConstants.services.subtitle)}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} className='grid-content-section textalign-center'>
                <Typography>
                    {translate(DefaultConstants.services.description)}
                </Typography>
            </Grid>

            <Grid item xs={12} className='grid-content-section paddingTop-none'>
                {
                    DefaultConstants.services.items.map((item, index) => {
                        return <Box key={index} className='service-container'>
                            <Box
                                className='service-image'
                                sx={{
                                    backgroundImage: `url(${item.wallpaper})`
                                }}
                            />

                            <Box className='service-informations'>
                                <Typography className='service-name'> {translate(item.name)} </Typography>
                                <Typography className='service-description'> {translate(item.homeDescription)} </Typography>
                                
                                <Button fullWidth={false} className='service-button' onClick={() => history.push(`/services/${item.id}`)}>
                                    {translate('readMore')}
                                </Button>
                            </Box>
                        </Box>;
                    })
                }
            </Grid>
        </Grid>
    </>;
}


export default Services;