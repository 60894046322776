
const TestimonialsTranslation = {
    testimonial1Message: {
        pt_br: "Sou supervisora das recepções dos Hotéis Hilton Garden Inn Santo André e Go Inn Santo André. Todas as fechaduras dos dois hotéis foram fornecidas pela Saga Systems! Já estamos em funcionamento por 8 meses, e até hoje nunca tive nenhum problema com as fechaduras, além do suporte personalizado que os técnicos nos oferecem a qualquer momento.  Agradeço por todo o serviço e atenção prestados por essa empresa. São verdadeiros parceiros.",
        en_us: "pendente",
        es_es: "pendente"
    },

    testimonial2Message: {
        pt_br: "Gostaríamos de agradecer os serviços e atendimento prestado pela SAGA SYSTEMS BRASIL! Nós realmente apreciamos e temos a certeza de uma parceria e satisfação. Tivemos nossas necessidades atendidas e estamos muito satisfeito com a reciprocidade e profissionalismo que atuam em seu segmento.",
        en_us: "pendente",
        es_es: "pendente"
    },

    testimonial3Message: {
        pt_br: "Tive um ótimo atendimento, inclusive no pós venda.",
        en_us: "pendente",
        es_es: "pendente"
    },

    testimonial4Message: {
        pt_br: "O produto e o atendimento são de primeira qualidade, além de que o software é muito prático e não deixa nenhum gap de segurança. É importante também destacar a implantação, que atendeu nossas necessidades particulares de forma muito profissional. Além disso, o suporte pós-implantação que minha equipe está recebendo é ótimo.",
        en_us: "pendente",
        es_es: "pendente"
    },

    testimonial5Message: {
        pt_br: "Diferentes dos concorrentes, a SAGA Systems tem pós vendas que realmente funciona. Dos materiais que comprei, passados anos não precisei de assistência técnica. Isto é ótimo.",
        en_us: "pendente",
        es_es: "pendente"
    },
}

export default TestimonialsTranslation;