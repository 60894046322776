import './style.scss';
import { Box, Grid, Typography } from "@mui/material";
import { useLanguageContext } from '../../../../../contexts/LanguageContext';
import DefaultConstants from '../../../../../data/Constants';
import { history } from '../../../../../router/BrowserHistory';
import { useParams } from 'react-router-dom';
import NotFound from '../../../../../components/NotFound';
import DocumentHelper from '../../../../../helpers/DocumentHelper';

const ProductCategory = (props) => {
    const { translate} = useLanguageContext();
    const params = useParams();
    
    const category = DefaultConstants.products.items.find(x => x.name === params.category);

    if (category === undefined) {
        return <NotFound text={translate('productNotFound')} />;
    }

    DocumentHelper.setDocumentTitle(translate(category.name));

    return <>
        <Grid container id='productcategory-section'>
            <Grid item xs={12} className='grid-content-section padding-none'>
                <Box className='wallpaper small' sx={{ backgroundImage: `url(${DefaultConstants.products.wallpaper})` }}>
                    <Typography className='wallpaper-title'>
                        {translate(category.name)}
                    </Typography>

                    <Typography className='wallpaper-subtitle'>
                        {translate('products')}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} className='grid-content-section textalign-center'>
                <Typography>
                    {translate(category.description)}
                </Typography>
            </Grid>

            <Grid item xs={12} className='grid-content-section paddingTop-none'>
                <Grid container className='display-flex justifycontent-center' gap={4}>
                    {
                        category.products.map((item, index) => {
                            return <Grid item lg={3} md={4} xs={12} key={index} className='product-container' onClick={() => history.push(`/products/${category.name}/${item.id}`)}>
                                <Box
                                    className='product-image'
                                    sx={{
                                        backgroundImage: `url(${item.wallpaper})`
                                    }}
                                />

                                <Box className='product-informations'>
                                    <Typography className='product-name'> {translate(item.name)} </Typography>
                                </Box>
                            </Grid>;
                        })
                    }
                </Grid>
            </Grid>
        </Grid>
    </>;
}


export default ProductCategory;