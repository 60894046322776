import './style.scss';
import { Box, Button, Grid, Typography } from "@mui/material";
import { useLanguageContext } from '../../../../contexts/LanguageContext';
import DefaultConstants from '../../../../data/Constants';
import { history } from '../../../../router/BrowserHistory';

const Products = (props) => {
    const { translate} = useLanguageContext();

    return <>
        <Grid container id='products-section'>
            <Grid item xs={12} className='grid-content-section padding-none'>
                <Box className='wallpaper small' sx={{ backgroundImage: `url(${DefaultConstants.products.wallpaper})` }}>
                    <Typography className='wallpaper-title'>
                        {translate(DefaultConstants.products.title)}
                    </Typography>

                    <Typography className='wallpaper-subtitle'>
                        {translate(DefaultConstants.products.subtitle)}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} className='grid-content-section textalign-center'>
                <Typography>
                    {translate(DefaultConstants.products.description)}
                </Typography>
            </Grid>

            <Grid item xs={12} className='grid-content-section paddingTop-none'>
                {
                    DefaultConstants.products.items.map((item, index) => {
                        return <Box key={index} className='product-container'>
                            <Box
                                className='product-image'
                                sx={{
                                    backgroundImage: `url(${item.image})`
                                }}
                            />

                            <Box className='product-informations'>
                                <Typography className='product-name'> {translate(item.name)} </Typography>
                                <Typography className='product-description'> {translate(item.homeDescription)} </Typography>
                                
                                <Button fullWidth={false} className='product-button' onClick={() => history.push(`/products/${item.name}`)}>
                                    {translate('readMore')}
                                </Button>
                            </Box>
                        </Box>;
                    })
                }
            </Grid>
        </Grid>
    </>;
}


export default Products;