import About from "../pages/Website/Content/About";
import Contact from "../pages/Website/Content/Contact";
import Home from "../pages/Website/Content/Home";
import Products from "../pages/Website/Content/Products";
import Services from "../pages/Website/Content/Services";

const NavBarLinks = [
    {
        path: '/',
        internal: true,
        name: 'home',
        component: Home
    }, {
        path: '/about',
        internal: true,
        name: 'about',
        component: About
    }, {
        path: '/products',
        internal: true,
        name: 'products',
        component: Products
    }, {
        path: '/services',
        internal: true,
        name: 'services',
        component: Services
    }, {
        path: '/contact',
        internal: true,
        name: 'contact',
        component: Contact
    }, {
        path: 'https://sagasystems.tomticket.com/',
        internal: false,
        name: 'technicalSupport',
        component: null
    }
];

export default NavBarLinks;

