
const ServicesTranslation = {
    _24HourTechnicalAssistance: {
        pt_br: "Assistência Técnica 24 horas",
        en_us: "pendente",
        es_es: "pendente"
    },

    _24HourTechnicalAssistanceDescription: {
        pt_br: <>
            <span>Agora você já pode abrir um chamado de suporte técnico da Saga Systems de forma rápida e fácil.</span>
            <br/> <br/>

            <span>Basta se cadastrar no site e criar um chamado preenchendo o formulário. Nosso departamento técnico fará o atendimento e você receberá um relatório por e-mail a cada atualização do seu chamado!</span>
            <br/> <br/>
            
            <ul>
                <li>Contrato de Assistência técnica com suporte apenas telefônico</li>
                <li>Contrato de Assistência técnica com suporte telefônico, manutenções preventivas e corretivas sem peças</li>
                <li>Contrato de Assistência técnica com suporte telefônico, manutenções preventivas e corretivas com peças</li>
            </ul>

            <span>Solicite o seu contrato através de um de nossos meios de contato.</span>
        </>,
        en_us: "pendente",
        es_es: "pendente"
    },

    _24HourTechnicalAssistanceHomeDescription: {
        pt_br: "Abra um chamado de suporte técnico da Saga Systems Brasil quando precisar.",
        en_us: "pendente",
        es_es: "pendente"
    },

    installationPeripheralsLocks: {
        pt_br: "Instalação de Periféricos e Fechaduras",
        en_us: "pendente",
        es_es: "pendente"
    },

    installationPeripheralsLocksDescription: {
        pt_br: <>
            <span>Contrate nossos serviços especializados para instalação de periféricos e fechaduras no seu empreendimento. Instalamos fechaduras em portas comuns, portas corta-fogo, leitores em elevador e leitores em portas de acessos restritos.</span>
        </>,
        en_us: "pendente",
        es_es: "pendente"
    },

    installationPeripheralsLocksHomeDescription: {
        pt_br: "Instalamos fechaduras em portas comuns, portas corta-fogo, leitores em elevador e leitores em portas de acessos restritos.",
        en_us: "pendente",
        es_es: "pendente"
    },

    preventiveCorrectiveMaintenance: {
        pt_br: "Manutenções Preventivas e Corretivas",
        en_us: "pendente",
        es_es: "pendente"
    },

    preventiveCorrectiveMaintenanceDescription: {
        pt_br: <>
            <span>Atendimento local para correção de falhas mecânicas ou no sistema.</span>
            <br/> 
            
            <br/>
            <span>
                <b>Manutenções Preventivas:</b> Assegure a funcionalidade e durabilidade das suas fechaduras eletrônicas, aumentando a eficiência, confiabilidade e segurança de seus equipamentos.
                Prolongue a vida útil destes equipamentos, diminuindo o desgaste eletrônico e mecânico de partes e peças:
            </span>
            <ul>
                <li>Limpeza de leitores e testes de funcionalidade das fechaduras e cofres</li>
                <li>Gravação das chaves emergenciais</li>
                <li>Atualização das fechaduras com informações precisas</li>
            </ul>

            <br/>
            <span>
                <b>Manutenções Corretivas:</b> Atendimento no local para correção de falhas mecânicas ou no sistema.
            </span>
        </>,
        en_us: "pendente",
        es_es: "pendente"
    },

    preventiveCorrectiveMaintenanceHomeDescription: {
        pt_br: "Atendimento local para correção de falhas mecânicas ou no sistema.",
        en_us: "pendente",
        es_es: "pendente"
    },

    recyclingTrainings: {
        pt_br: "Treinamentos de reciclagem",
        en_us: "pendente",
        es_es: "pendente"
    },

    recyclingTrainingsDescription: {
        pt_br: <>
            <span>Treinamento do sistema de fechaduras eletrônicas aos principais departamentos responsáveis pela operação do sistema.</span>
            <br/> 
            
            <br/>
            <span>
                <b>Gerência Geral:</b> Treinamento completo de todas as rotinas do sistema:
            </span>
            <ul>
                <li>Procedimentos de chaves mestras</li>
                <li>Procedimento de chaves de segurança e chaves especiais</li>
                <li>Procedimentos de auditoria</li>
                <li>Procedimentos de cadastro de usuários e níveis de acesso</li>
            </ul>

            <br/>
            <span>
                <b>Recepção:</b> Treinamento das rotinas operacionais da recepção:
            </span>
            <ul>
                <li>Procedimento de gravação de chaves para hóspede</li>
                <li>Ações de segurança em caso de perda de chaves</li>
                <li>Procedimentos para gerar cópias de chaves, chaves múltiplas, abertura única, etc.</li>
            </ul>

            <br/>
            <span>
                <b>Manutenção:</b> Treinamento voltado a conhecimentos específicos de funcionamento dos produtos:
            </span>
            <ul>
                <li>Conhecimentos de funcionamento da fechadura, entendendo os sinais luminosos emitidos por esta, identificação de pilhas fracas, etc.</li>
                <li>Procedimentos de segurança para o uso das chaves mestras.</li>
                <li>Procedimentos para troca de pilhas</li>
                <li>Procedimento para montagem e desmontagem da fechadura.</li>
                <li>Procedimentos de abertura de emergência da fechadura.</li>
            </ul>

            <br/>
            <span>
                <b>Governança:</b> Treinamento voltado a limpeza e conservação das fechaduras, e conhecimentos sobre seu funcionamento:
            </span>
            <ul>
                <li>Conhecimentos de funcionamento da fechadura, entendendo os sinais luminosos emitidos por esta, identificação de pilhas fracas, etc.</li>
                <li>Procedimentos de segurança para o uso das chaves mestras.</li>
                <li>Procedimentos para limpeza e conservação das fechaduras.</li>
            </ul>
        </>,
        en_us: "pendente",
        es_es: "pendente"
    },

    recyclingTrainingsHomeDescription: {
        pt_br: "Treinamento do sistema de fechaduras eletrônicas aos principais departamentos responsáveis pela operação do sistema.",
        en_us: "pendente",
        es_es: "pendente"
    },

    specializedTechnicalTeam: {
        pt_br: "Equipe Técnica Especializada",
        en_us: "pendente",
        es_es: "pendente"
    },

    specializedTechnicalTeamDescription: {
        pt_br: <>
        <span>Organize seu sistema de fechaduras eletrônicas, aumente o nível de segurança.</span>
        <br/> <br/>
        
        <ul>
            <li>Revisão da arquitetura geral do sistema organizando os acessos e autorizações de funcionários Reciclagem de senhas de acesso</li>
            <li>Organização da lista geral de portas instaladas</li>
            <li>Reformulação da lista de mestras e acessos</li>
        </ul>
    </>,
        en_us: "pendente",
        es_es: "pendente"
    },

    specializedTechnicalTeamHomeDescription: {
        pt_br: "Organize seu sistema de fechaduras eletrônicas, aumente o nível de segurança.",
        en_us: "pendente",
        es_es: "pendente"
    },

    systemsRestructuring: {
        pt_br: "Reestruturação de sistemas",
        en_us: "pendente",
        es_es: "pendente"
    },

    systemsRestructuringDescription: {
        pt_br: <>
            <span>Organize seu sistema de fechaduras eletrônicas e aumente o nível de segurança do seu empreendimento.</span>
            <br/> <br/>
            <ul>
                <li> Revisão da arquitetura geral do sistema, organizando os acessos e autorizações de funcionários </li>
                <li> Reciclagem de senhas de acesso </li>
                <li> Organização da lista geral de portas instaladas </li>
                <li> Reformulação da lista de mestras e acessos </li>
            </ul>
        </>,
        en_us: "pendente",
        es_es: "pendente"
    },

    systemsRestructuringHomeDescription: {
        pt_br: "Organize seu sistema de fechaduras eletrônicas e aumente o nível de segurança do seu empreendimento.",
        en_us: "pendente",
        es_es: "pendente"
    },
}

export default ServicesTranslation;