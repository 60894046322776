import { Box, Button, Typography } from '@mui/material';
import './style.scss';
import { history } from '../../router/BrowserHistory';
import { useLanguageContext } from '../../contexts/LanguageContext';


const NotFound = ({
    text
}) => {
    const { translate } = useLanguageContext();

    return <Box className='notfound-default'>
        <Typography className='notfound-title'>404</Typography>
        <Typography className='notfound-message'>{text}</Typography>

        <Button className='notfound-button' onClick={() => history.goBack()} variant='contained'>
            {translate('back')}
        </Button>
    </Box>;
}

export default NotFound;
