import { Box, Typography } from '@mui/material';
import './style.scss';
import Slider from '../Slider';
import DefaultConstants from '../../data/Constants';

const Clients = () => {
    return <Box className='clients-default'>
        <Slider 
            arrows={true}
            items={DefaultConstants.clients.map(c => {
                return <Box 
                    className='client-image' 
                    key={c.name} 
                    sx={{
                        backgroundImage: `url(${c.image})`
                    }}
                >
                    <Typography className='client-name'>{c.name}</Typography>
                </Box>;
            })}
        />
    </Box>;
}

export default Clients;