import './style.scss';
import { Backdrop, Box, Grid, IconButton, Typography } from "@mui/material";
import { useLanguageContext } from '../../../../../contexts/LanguageContext';
import DefaultConstants from '../../../../../data/Constants';
import { useParams } from 'react-router-dom';
import NotFound from '../../../../../components/NotFound';
import Slider from '../../../../../components/Slider';
import DocumentHelper from '../../../../../helpers/DocumentHelper';
import { useState } from 'react';
import { Close } from '@mui/icons-material';

const ProductDetails = (props) => {
    const { translate, currentLanguage } = useLanguageContext();
    const params = useParams();

    const [backdropOpen, setBackdropOpen] = useState(false);
    
    const category = DefaultConstants.products.items.find(x => x.name === params.category);

    if (category === undefined) {
        return <NotFound text={translate('productNotFound')}/>;
    }

    const product = category.products.find(x => x.id === params.id);

    if (product === undefined) {
        return <NotFound text={translate('productNotFound')}/>;
    }

    DocumentHelper.setDocumentTitle(translate(product.name));

    return <>
        <Grid container id='productdetails-section'>
            <Grid item xs={12} className='grid-content-section padding-none'>
                <Box className='wallpaper small' sx={{ backgroundImage: `url(${DefaultConstants.products.wallpaper})` }}>
                    <Typography className='wallpaper-title'>
                        {translate(product.name)}
                    </Typography>

                    <Typography className='wallpaper-subtitle'>
                        {translate(category.name)}
                    </Typography>
                </Box>
            </Grid>

            <Grid item md={4} xs={12} className='grid-content-section'>
                <img 
                    src={product.images[currentLanguage.value][0]} 
                    alt={`${product.name}-wallpaper`} 
                    className='product-wallpaper' 
                    onClick={() => setBackdropOpen(true)}
                />
            </Grid>

            <Grid item md={8} xs={12} className='grid-content-section'>
                <Typography className='product-description'>
                    {translate(product.description)}
                </Typography>
            </Grid>

            <Grid item xs={12} className='grid-content-section backgroundcolor-grey'>
                <Slider
                    arrows={true}
                    slidesPerView={4}
                    interval={0}
                    items={product.images[currentLanguage.value].map((item, index) => {
                        return <img 
                            src={item} 
                            alt={`${product.name}-${index}`} 
                            key={index} 
                            className='product-image' 
                            onClick={() => setBackdropOpen(true)}
                        />;
                    })}
                />
            </Grid>
        </Grid>

        <Backdrop open={backdropOpen} className='backdrop-product-container'>
            <Box className='box-close'>
                <IconButton onClick={() => setBackdropOpen(false)}>
                    <Close fontSize='large' />
                </IconButton>
            </Box>

            <Box className='box-slider'>
                <Slider
                    arrows={true}
                    dots={true}
                    slidesPerView={1}
                    interval={0}
                    items={product.images[currentLanguage.value].map((item, index) => {
                        return <img src={item} alt={`${product.name}-${index}`} key={index} className='backdrop-product-image' />;
                    })}
                />
            </Box>
        </Backdrop>
    </>;
}


export default ProductDetails;