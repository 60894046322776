import { Avatar, Box, Typography } from '@mui/material';
import './style.scss';
import Slider from '../Slider';
import DefaultConstants from '../../data/Constants';
import { useLanguageContext } from '../../contexts/LanguageContext';


const Testimonials = () => {
    const { translate } = useLanguageContext();

    return <Box className='testimonials-default'>
        <Slider 
            dots={true}
            slidesPerView={1}
            items={DefaultConstants.testimonials.map(t => {
                return <Box className='testimonial-item' key={t.name}>
                    <Typography className='testimonial-message'>{translate(t.message)}</Typography>

                    <Box className='testimonial-profile'>
                        <Avatar src={t.avatar} className='testimonial-avatar' />
                        <Typography className='testimonial-client'>
                            {t.client}
                            
                            {
                                t.person && <Typography className='testimonial-person'>{t.person}</Typography>
                            }
                        </Typography>
                    </Box>
                </Box>;
            })}
        />
    </Box>;
}

export default Testimonials;
