import { Box, Typography } from '@mui/material';
import './style.scss';
import Slider from '../Slider';
import DefaultConstants from '../../data/Constants';
import { useLanguageContext } from '../../contexts/LanguageContext';

const Employees = () => {
    const { translate } = useLanguageContext();

    return <Box className='employees-default'>
        <Slider 
            arrows={true}
            items={DefaultConstants.employees.map(e => {
                return <Box 
                    className='employee-item' 
                    key={e.name} 
                >
                    <img className='employee-avatar' src={e.avatar} alt={e.name} />
                    <Typography className='employee-name'>{e.name}</Typography>
                    <Typography className='employee-position'>{translate(e.position)}</Typography>
                    <Typography className='employee-location'>{e.location}</Typography>
                    <Typography className='employee-phone' component='a' href={`tel:${e.phone.match(/\d+/gi).join('')}`}>{e.phone}</Typography>
                    <Typography className='employee-email' component='a' href={`mailto:${e.email}`}>{e.email}</Typography>
                </Box>;
            })}
        />
    </Box>;
}

export default Employees;