import './style.scss';

import { Grid, Typography, IconButton, Backdrop, Box } from "@mui/material";
import DefaultConstants from "../../../data/Constants";
import { ClientImages } from "../../../data/Images";
import { useLanguageContext } from '../../../contexts/LanguageContext';
import { useCallback, useEffect, useState } from 'react';
import { Close, Menu } from '@mui/icons-material';
import { history } from '../../../router/BrowserHistory';
import NavBarLinks from '../../../data/NavBar';
import DocumentHelper from '../../../helpers/DocumentHelper';


const Header = () => {
    const { translate } = useLanguageContext();
    
    const [navLinkSelected, setNavLinkSelected] = useState('/');
    const [navBarActived, setNavBarActived] = useState(false);
    const [scrolling, setScrolling] = useState(false);
    

    useEffect(() => {
        handleScroll();
        setNavBarActived(false);
        setNavLinkSelected(history.location.pathname);

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        const content = document.getElementById('website-content');
        if (content) {
            content.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        // eslint-disable-next-line
    }, [history.location.pathname]);


    window.onscroll = useCallback(() => {
        handleScroll();
        // eslint-disable-next-line
    }, []);


    const handleScroll = useCallback(() => {
        const scrolled = window.scrollY > 60;
        const hasWallpaper = document.getElementsByClassName('wallpaper-title').length > 0;
        const isHomePage = !!(document.getElementById('home-section'));

        setScrolling(scrolled || (!hasWallpaper && !isHomePage));
    }, []);

    return (
        <>
            <Grid container className='grid-header-website' id='website-header'>
                <Grid item xs={12} className={`grid-header ${scrolling ? 'scrolling' : ''}`}>
                    <Grid item className='grid-navbar-trigger-open'>
                        <IconButton onClick={() => setNavBarActived(true)}>
                            <Menu />
                        </IconButton>
                    </Grid>

                    <img
                        src={ClientImages.LogoHorizontalWhite}
                        alt={DefaultConstants.systemName}
                        className='image-logo cursor-pointer'
                        onClick={() => history.push('/')}
                    />

                    <Grid item xs={12} className={`grid-navbar ${navBarActived ? 'actived' : ''}`}>
                        <Box className='grid-navbar-trigger-close'>
                            <IconButton onClick={() => setNavBarActived(false)}>
                                <Close />
                            </IconButton>
                        </Box>

                        {
                            NavBarLinks.map((link, index) => {
                                const isSelected = link.path === navLinkSelected ? 'selected' : '';

                                return (
                                    <Typography 
                                        key={index} 
                                        className={`grid-navbar-item ${isSelected}`} 
                                        onClick={() => {
                                            if (!link.internal) {
                                                window.open(link.path);
                                                return;
                                            }

                                            history.push(link.path);
                                            DocumentHelper.setDocumentTitle(translate(link.name));
                                        }}
                                    >
                                        {translate(link.name)}
                                    </Typography>
                                );
                            })
                        }
                    </Grid>

                    <Backdrop open={navBarActived} onClick={() => setNavBarActived(false)} className='backdrop-navbar' />
                </Grid>
            </Grid>
        </>
    );
}


export default Header;