import './style.scss';

import { Button, Divider, Grid, IconButton, Typography } from "@mui/material";
import DefaultConstants from '../../../data/Constants';
import { useLanguageContext } from '../../../contexts/LanguageContext';
import { Email, Phone, SupportAgent } from '@mui/icons-material';


const Footer = (props) => {
    const { translate } = useLanguageContext();
    
    return (
        <Grid container 
            className='grid-footer-website'
            columnSpacing={4}
            rowSpacing={2} 
            sx={{ backgroundImage: `url(${DefaultConstants.footer.wallpaper})` }}
        >
            <Grid item md={4} xs={12}>
                <Typography variant='body2' className='text-footer text-title'> 
                    {DefaultConstants.systemName}
                </Typography>

                <Typography variant='body2' className='text-footer'> 
                    {translate("footerDescription1")}
                </Typography>
            </Grid>

            <Grid item md={4} xs={12}>
                <Typography variant='body2' className='text-footer text-title'> 
                    {translate('reliability')}
                </Typography>

                <Typography variant='body2' className='text-footer'> 
                    {translate("footerDescription2")}
                </Typography>

                <Button
                    type='button'
                    variant='contained'
                    disableElevation
                    startIcon={
                        <SupportAgent fontSize='medium' />
                    }
                    className='backgroundcolor-primary'
                    onClick={() => window.open('https://www.reclameaqui.com.br/empresa/saga-systems/?utm_source=referral&utm_medium=embbed&utm_campaign=reputacao&utm_term=vertical')}
                    sx={{
                        marginTop: 4
                    }}
                >
                    Reclame aqui
                </Button>
            </Grid>

            <Grid item md={4} xs={12} className='grid-socialmedia'>
                <Typography variant='body2' className='text-footer text-title'> 
                    {translate('contact')}
                </Typography>

                <Typography variant='body2' className='text-footer text-contact' onClick={() => window.open(`tel:${DefaultConstants.phones[0].match(/\d+/gi).join('')}`)}> 
                    <Phone />
                    {DefaultConstants.phones[0]}
                </Typography>

                <Typography variant='body2' className='text-footer text-contact' onClick={() => window.open(`mailto:${DefaultConstants.emails[0]}`)}> 
                    <Email />
                    {DefaultConstants.emails[0]}
                </Typography>

                {
                    DefaultConstants.socialMedias.filter(s => s.url !== undefined).map((s, index) => {
                        return (
                            <IconButton className='iconbutton-socialmedia' onClick={() => window.open(s.url)} key={index}>
                                <s.Icon />
                            </IconButton>
                        );
                    })
                }
            </Grid>

            <Grid item xs={12}>
                <Divider className='footer-divider' />
            </Grid>

            <Grid item xs={12} className='display-flex flexdirection-column justifycontent-center textalign-center'>
                <Typography variant='body2' className='text-footer text-copyright'> 
                    {translate("copyright")}
                </Typography>
                
                <Typography variant='caption' className='text-footer text-developed'> 
                    {translate("developedBy")}
                    {' '}
                    <Typography variant='caption' component='a' className='text-footer text-developer' href={DefaultConstants.developer.url} target='_blank'>
                        {DefaultConstants.developer.name} 
                    </Typography>
                </Typography>
            </Grid>
        </Grid>
    );
}


export default Footer;